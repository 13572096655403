import React, { useEffect, useState } from 'react'

const SuccessHandler = () => {
  const redirect = window.sessionStorage.getItem('callbackurl')

  window.sessionStorage.removeItem('token')

  if (redirect) {
    // setTimeout(() => {
    //   window.sessionStorage.removeItem('callbackurl')
    //   window.location.href = `${redirect}`
    // }, 3000)
  }

  return (
    <>
      <h1>
        <span>All </span>set!
      </h1>
      <hr />
      <p className="text-center">
      Your facial registration has been successfully completed!
        <br />
        <br />
        Thank you!
      </p>
    </>
  )
}

export default SuccessHandler
