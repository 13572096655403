import React, { Component, useCallback, useEffect, useState } from 'react'
import './Biometric/OnboardCard.sass'

import {
  BsEmojiSunglasses,
  BsSun,
  BsPeople,
  BsPersonBoundingBox,
  BsEmojiWink
} from 'react-icons/bs'

interface Props {
  onResponse: (response: any) => void
}

function Welcome({ onResponse }: Props) {
  const [photoState, setphotoState] = useState<string>('')

  const handleResponse = useCallback((message: string) => {
    setphotoState(message)
  }, [])

  useEffect(() => {
    const params = new URLSearchParams(window.location.search) // id=123
    if (params.get('fixBrowser')) {
      setphotoState('mobile')
    }
  }, [])

  return (
    <div className={`${photoState} card`}>
      <h1>
      Welcome<span>!</span>
      </h1>
      <hr />
      <p className="text-center">
      Thank you for participating in this great transformation for our institution! In a few steps, we will help you register your facial biometrics to access the club in an easier, faster, and more technological way!
      </p>
      <div className="form-group form-submit">
        <a
          className="btn btn-primary form-control form-control-lg"
          onClick={() => onResponse({ step: 'signup' })}
        >
          {`Let's get started!`}
        </a>
      </div>
    </div>
  )
}

export default Welcome
